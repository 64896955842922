
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Order } from '@/models'

@Component({
  name: 'ActivityBlock'
})
export default class extends Vue {
  @Prop({ required: true }) private displayFrom!: string

  panels: any[] = [
    { value: 0, title: '', currency: false },
    { value: 0, title: '', currency: false }
  ]

  private createdAt = {
    gte: this.$moment().subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss'),
    lte: this.$moment().format('YYYY-MM-DD HH:mm:ss')
  }

  created() {
    this.getOrdersByDate()
    this.getOrdersToDelivery()
  }

  // #################
  // REQUEST FOR DASHBOARD
  // #################

  private async getOrdersByDate() {
    const { meta } = await Order.stats({ total: 'count' }).all()

    this.panels[0].value = meta.stats.total.count
    this.panels[0].title = 'orders'
  }

  private async getOrdersToDelivery() {
    const { meta } = await Order
      .where({ deliveryStatus: 'undelivered' })
      .where({ deliveryStatus: 'in_delivery' })
      .stats({ total: 'count' })
      .all()

    this.panels[1].value = meta.stats.total.count
    this.panels[1].title = 'ordersNotDelivered'
  }
}
