
import { Component, Vue } from 'vue-property-decorator'
import { useUserStore } from '@/stores/user'

@Component({
  name: 'HelloBlock'
})
export default class extends Vue {
  userStore: any = useUserStore()

  get user() {
    return this.userStore.model
  }

  get roles() {
    return this.userStore.roles
  }
}
