
import { Component, Vue, Watch } from 'vue-property-decorator'

import { PurchaseVolume } from '@/models/index'

import { useUserStore } from '@/stores/user'
import { useDashboardStore } from '@/stores/dashboard'

@Component({
  name: 'PurchaseVolumeStatistics',
  components: {
    VolumesBarChart: () => import('./VolumesBarChart.vue'),
    DialogVolumesBarChart: () => import('./DialogVolumesBarChart.vue'),
    LeaderboardChart: () => import('./LeaderboardChart.vue')
  }
})
export default class extends Vue {
  userStore: any = useUserStore()
  dashboardStore: any = useDashboardStore()

  periods = [{
    value: 'last_month',
    label: this.$t('dashboard.periods.lastMonth')
  }, {
    value: 'day',
    label: this.$t('dashboard.periods.today')
  }, {
    value: 'week',
    label: this.$t('dashboard.periods.week')
  }, {
    value: 'month',
    label: this.$t('dashboard.periods.month')
  }, {
    value: 'year',
    label: this.$t('dashboard.periods.year')
  }]

  @Watch('selectedPeriod')
  private onSelectedPeriodChange(value: any) {
    this.refresh()
  }

  @Watch('salesMode')
  private onSalesModeChange(value: any) {
    this.refresh()
  }

  get loading(): boolean {
    return this.dashboardStore.loading
  }

  get purchaseVolumes(): PurchaseVolume[] {
    return this.dashboardStore.purchaseVolumes
  }

  get selectedPeriod(): string {
    return this.dashboardStore.period
  }

  set selectedPeriod(value: string) {
    this.dashboardStore.period = value
  }

  get purchaseCount(): number {
    return this.dashboardStore.purchaseCount
  }

  get totalAmount(): number {
    return this.dashboardStore.totalAmount
  }

  get faceValueTotalAmount(): number {
    return this.dashboardStore.faceValueTotalAmount
  }

  get salesMode(): boolean {
    return this.dashboardStore.salesMode
  }

  async created(): Promise<void> {
    await this.dashboardStore.getPurchaseVolumes()
  }

  async refresh(): Promise<void> {
    await this.dashboardStore.getPurchaseVolumes()
  }

  setClassTrend(amount: number): string {
    return amount === 0 ? 'badge-debit' : 'badge-success'
  }

  setIconTrend(amount: number): string {
    return amount === 0  ? 'el-icon-bottom' : 'el-icon-top'
  }
}
