
import { Component, Vue, Watch } from 'vue-property-decorator'

import { PurchaseVolume } from '@/models/index'

import { useDashboardStore } from '@/stores/dashboard'

import { Bar, Doughnut } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, ArcElement, CategoryScale, LinearScale } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, CategoryScale, BarElement, ArcElement, CategoryScale, LinearScale)

@Component({
  name: 'VolumesBarChart',
  components: {
    Bar,
    Doughnut
  }
})
export default class extends Vue {
  @Watch('period')
  private onPeriodChange(newPeriod: string) {
    if (newPeriod === 'day') this.volumesBy = 'giftCards'
  }

  dashboardStore: any = useDashboardStore()

  salesOptions = [{
    value: true,
    label: this.$t('dashboard.volumesBarChart.options.sell')
  }, {
    value: false,
    label: this.$t('dashboard.volumesBarChart.options.buy')
  }]

  chartOptions: any = {
    responsive: true,
    maintainAspectRatio: false
    // scales: {
    //   x: {
    //     stacked: true,
    //   },
    //   y: {
    //     stacked: true
    //   }
    // }
  }

  created() {
    if (this.isWholesaler) this.salesMode = true
  }

  get loading(): boolean {
    return this.dashboardStore.loading
  }

  get purchaseVolumes(): PurchaseVolume[] {
    return this.dashboardStore.purchaseVolumes
  }

  get chartVolumesData(): string {
    return this.dashboardStore.chartVolumesData
  }

  get salesMode() {
    return this.dashboardStore.salesMode
  }

  set salesMode(value: boolean) {
    this.dashboardStore.salesMode = value
  }

  get volumesBy(): string {
    return this.dashboardStore.volumesBy
  }

  set volumesBy(value: string) {
    this.dashboardStore.volumesBy = value
  }

  get period(): string {
    return this.dashboardStore.period
  }

  get chartFullscreen(): boolean {
    return this.dashboardStore.volumesChartFullscreen
  }

  set chartFullscreen(value: boolean) {
    this.dashboardStore.volumesChartFullscreen = value
  }

  get isWholesaler(): boolean {
    return this.dashboardStore.isWholesaler
  }
}
