
import { Component, Prop, Vue } from 'vue-property-decorator'
import { useDashboardStore } from '@/stores/dashboard'

@Component({
  name: 'DialogVolumesBarChart',
  components: {
    VolumesBarChart: () => import('./VolumesBarChart.vue')
  }
})
export default class extends Vue {
  @Prop({ required: false, default: false }) fullscreen!: boolean

  dashboardStore: any = useDashboardStore()

  get chartFullscreen(): boolean {
    return this.dashboardStore.volumesChartFullscreen
  }

  set chartFullscreen(value: boolean) {
    this.dashboardStore.volumesChartFullscreen = value
  }
}
