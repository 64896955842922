
import { Component, Vue } from 'vue-property-decorator'

import { PurchaseVolume } from '@/models/index'

import { useDashboardStore } from '@/stores/dashboard'
import { useUserStore } from '@/stores/user'

import ProgressBar from 'vue-simple-progress'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

@Component({
  name: 'LeaderboardChart',
  components: {
    ProgressBar,
  }
})
export default class extends Vue {
  userStore: any = useUserStore()
  dashboardStore: any = useDashboardStore()

  colors = [
    '#FF6384', '#36A2EB', '#FFCE56', '#F58231', '#46F0F0', '#D2F53C', '#911EB4', '#F032E6',
    '#3CB44B', '#FFE119', '#E6194B', '#FABEBE', '#008080', '#E6BEFF', '#0082C8', '#AA6E28',
    '#FFFAC8', '#800000', '#AAFFC3', '#808000', '#FFD8B1', '#000080', '#808080', '#000000'
  ];

  leaderboardChartType = 'bar'
  leaderboardBy = 'giftCards'

  leaderboardOptions = [{
    value: 'giftCards',
    label: this.$t('dashboard.leaderboardChart.options.giftCards')
  }, {
    value: 'buyers',
    label: this.$t('dashboard.leaderboardChart.options.buyers')
  }]

  chartOptions: any = {
    responsive: true,
    maintainAspectRatio: false
  }

  get salesMode() {
    return this.dashboardStore.salesMode
  }

  get loading(): boolean {
    return this.dashboardStore.loading
  }

  get purchaseVolumes(): PurchaseVolume[] {
    return this.dashboardStore.purchaseVolumes
  }

  get giftCardsLeaderboard(): any {
    return this.dashboardStore.giftCardsLeaderboard
  }

  get buyersLeaderboard(): any {
    return this.dashboardStore.buyersLeaderboard
  }

  get isWholesaler() {
    return this.userStore.roles.includes('wholesaler')
  }

  // LEADERBOARD CHART BAR
  get chartLeaderboardBarData(): any {
    let chartLeaderBoardData = null

    if (this.leaderboardBy === 'giftCards') chartLeaderBoardData = this.giftCardsLeaderboard
    else chartLeaderBoardData = this.buyersLeaderboard

    return chartLeaderBoardData
  }
  //-  LEADERBOARD CHART BAR
}
