
import { Component, Vue } from 'vue-property-decorator'
import { GiftCard, Program, SaleVolume } from '@/models'

@Component({
  name: 'SaleVolumeBlock',
  components: {
    GiftCardSelect: () => import('@/components/GiftCardSelect/index.vue')
  }
})
export default class extends Vue {
  private giftCards: GiftCard[] = []
  private giftCardsLoading = false

  private salesVolume: SaleVolume[] = []
  private salesVolumeLoading = false

  private programs: Program[] = []
  private programsLoading = false

  private filters: any = {
    date: new Date(),
    giftCardId: '',
    programId: ''
  }

  get salesVolumeAvailable() {
    return this.salesVolume.length > 0
  }

  get giftCardSelected() {
    return this.filters.giftCardId
  }

  get totalSales(): number {
    return this.salesVolumeAvailable ? this.salesVolume.reduce((acc: number, current: SaleVolume): number => {
      return acc + current.sales
    }, 0) : 0
  }

  get totalFaceValues(): number {
    return this.salesVolumeAvailable ? this.salesVolume.reduce((acc: number, current: SaleVolume): number => {
      return acc + current.facialValueAmount
    }, 0) : 0
  }

  get totalMultiChoiceCardAmount(): number {
    return this.salesVolumeAvailable ? this.salesVolume.reduce((acc: number, current: SaleVolume): number => {
      return acc + current.multiChoiceCardAmount
    }, 0) : 0
  }

  get totalDiscountsValue(): number {
    return this.salesVolumeAvailable ? this.salesVolume.reduce((acc: number, current: SaleVolume): number => {
      return acc + current.discountAmount
    }, 0) : 0
  }

  get discountRate(): string {
    return (this.salesVolume[0]?.averageDiscountRate || 0).toFixed(2)
  }

  get totalCommissionValue(): number {
    return this.salesVolumeAvailable ? this.salesVolume.reduce((acc: number, current: SaleVolume): number => {
      return acc + current.commissionAmount
    }, 0) : 0
  }

  get commissionRate(): string {
    return (this.salesVolume[0]?.averageCommissionRate || 0).toFixed(2)
  }

  get totalMarginValue(): number {
    return this.salesVolumeAvailable ? this.salesVolume.reduce((acc: number, current: SaleVolume): number => {
      return acc + current.marginAmount
    }, 0) : 0
  }

  get marginRate(): string {
    return (this.salesVolume[0]?.averageMarginRate || 0).toFixed(2)
  }

  private created() {
    this.getSalesVolume()
    this.getGiftCards()
    this.getPrograms()
  }

  private async getSalesVolume() {
    this.salesVolumeLoading = true
    const createdAtGte = this.filters.date ? this.$moment(String(this.filters.date)).startOf('month').format() : ''
    const createdAtLte = this.filters.date ? this.$moment(String(this.filters.date)).endOf('month').format() : ''

    const { data } = await SaleVolume
      .where(
        {
          giftCardId: this.filters.giftCardId,
          programId: this.filters.programId,
          createdAt: { gte: encodeURIComponent(createdAtGte), lte: encodeURIComponent(createdAtLte) }
        }
      )
      .includes(['program'])
      .page(1)
      .per(999)
      .all()

    this.salesVolume = data
    this.salesVolumeLoading = false
  }

  private async getGiftCards() {
    this.giftCardsLoading = true

    const { data } = await GiftCard
      .per(999)
      .page(1)
      .order('name')
      .all()

    this.giftCards.push(...data)

    this.giftCardsLoading = false
  }

  private async getPrograms() {
    this.programsLoading = true

    const { data } = await Program
      .page(1)
      .per(999)
      .all()

    this.programs.push(...data)
    this.programsLoading = false
  }

  private handleGiftCardSelect(giftCardId: string) {
    this.salesVolumeLoading = true
    this.filters.giftCardId = giftCardId
    this.getSalesVolume()
  }
}
