
import { Component, Vue } from 'vue-property-decorator'
import { useUserStore } from '@/stores/user'

@Component({
  name: 'Dashboard',
  components: {
    PurchaseVolumeStatistics: () => import('./components/PurchaseVolumeStatistics.vue'),
    HelloBlock: () => import('./components/HelloBlock.vue'),
    ActivitySellerBlock: () => import('./components/ActivitySellerBlock.vue'),
    SaleVolumeBlock: () => import('./components/SaleVolumeBlock.vue')
  }
})
export default class extends Vue {
  userStore: any = useUserStore()

  get roles() {
    return this.userStore.roles
  }
}
